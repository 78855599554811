<template>
  <div class="about">
    <h1>Currículo</h1>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
embed {
  background-color: red;
}
</style>
